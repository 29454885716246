import { useRecoilState } from 'recoil';
import { defaultActiveWithdrawalsFilter } from '../../../../../../../../utils/states/activeFilter.state';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetFilesQuery,
  useGetWithdrawalsQuery,
  useUpdateWithdrawalStatusMutation,
} from '../../../../../../../../redux/services/WithdrawalsApi';
import calculateElements from '../../../../../../../../utils/functions/calcucateElements';
import { Table } from '../../../../../../../../components/Table/Table';
import {
  getOrderStyle,
  withdrawalsThArray,
} from '../../../../../../../../utils/constants/th_arrays';
import { mapWithdrawals } from '../../../../../../../../utils/functions/map/mapWithdrawals';
import { Pagination } from '../../../../../../../../components/Pagination/Pagination';
import { Selector } from '../../../../../components/Selector/Selector';
import styles from '../../../../../components/Filter/Filter.module.css';
import { Button } from 'components/Button/Button';
import { ModalDefault } from 'components/ModalDefault/ModalDefault';
import sanitizeHtml from 'sanitize-html';
import ContentEditable from 'react-contenteditable';
import formatNumber from 'utils/functions/formatNumber';
import { AcceptedFiles, fancyTimeFormat } from 'utils/constants/text';
import { pushNotify } from 'components/Notifications';
import { bytesToMegaBytes } from 'utils/functions/bytesToMEgabytes';
import { handleDownloadFile } from 'utils/functions/downloadFile';
import { createPortal } from 'react-dom';

const WithdrawalsTable = () => {
  const [{ activeWithdrawalsFilter }] = useRecoilState(defaultActiveWithdrawalsFilter);
  const [file1, setFile1] = useState<File[]>([]);
  const ref1 = useRef<HTMLInputElement>(null);
  const [file2, setFile2] = useState<File[]>([]);
  const ref2 = useRef<HTMLInputElement>(null);
  const [trouble, setTrouble] = useState('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [searchId, setSearchId] = useState<string>();
  const [modalActive, setModalActive] = useState(false);
  const [troubleModalActive, setTroubleModalActive] = useState(false);
  const [previewActive, setPreviewActive] = useState(false);
  const [preview1Active, setPreview1Active] = useState(false);
  const [prevCount, setPrevCount] = useState<number | null | undefined>();
  const [openSelector, setOpenSelector] = useState<string | null>(null);
  const [withdrawalPreviewOpen, setWithdrawalPreviewOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { data } = useGetWithdrawalsQuery(
    { status: activeWithdrawalsFilter, page, page_size: pageSize, id: searchId?.trim() || '' },
    { pollingInterval: 1000 },
  );
  const { data: disputes } = useGetWithdrawalsQuery(
    { status: 'ID', page: undefined, page_size: undefined },
    { pollingInterval: 1000 },
  );
  const { data: withdrawalFiles } = useGetFilesQuery(
    { id: selectedId || '' },
    { skip: !selectedId, refetchOnMountOrArgChange: true },
  );
  const selectedWithdrawal = data?.results.find((withdrawal) => withdrawal.id === selectedId);

  useEffect(() => {
    if (disputes && prevCount === undefined) {
      setPrevCount(disputes.count);
    }
  }, [disputes, prevCount]);

  useEffect(() => {
    if (disputes && prevCount !== null && prevCount !== undefined) {
      if (disputes.count > prevCount) {
        pushNotify(`НОВЫЙ СПОР`, 'ВНИМАНИЕ, НОВЫЙ СПОР');
        setPrevCount(disputes.count);
      }
      setPrevCount(disputes.count);
    }
  }, [disputes]);

  const [updateWithdrawal] = useUpdateWithdrawalStatusMutation();
  const fileURLS1 = useMemo(
    () =>
      file1.map((file) => {
        return {
          url: URL.createObjectURL(file),
          type:
            file.type === 'video/mp4'
              ? 'video'
              : file.type === 'application/pdf'
              ? 'document'
              : 'image',
        };
      }),
    [file1],
  );
  const fileURLS = useMemo(
    () =>
      file2.map((file) => {
        return {
          url: URL.createObjectURL(file),
          type:
            file.type === 'video/mp4'
              ? 'video'
              : file.type === 'application/pdf'
              ? 'document'
              : 'image',
        };
      }),
    [file2],
  );
  const [activeFile1Id, setActiveFile1Id] = useState<number | null>(null);
  const [activeFileId, setActiveFileId] = useState<number | null>(null);

  const handleEdit = async (newStatus: string, id: string) => {
    const body = new FormData();
    body.append('status', newStatus);
    await updateWithdrawal({ body, id });
  };

  const handleOpenModal = (id: string) => {
    setFile1([]);
    setSelectedId(id);
    setModalActive(true);
  };

  // @ts-ignore
  const onContentChange = useCallback((e) => {
    if (e.currentTarget.innerHTML.length > 1000) return;

    const sanitizeConf = {
      allowedTags: ['b', 'i', 'a', 'p'],
      allowedAttributes: { a: ['href'] },
    };

    setTrouble(sanitizeHtml(e.currentTarget.innerHTML, sanitizeConf));
  }, []);

  const handleAccept = (status: 'SF' | 'STS') => {
    const formData = new FormData();
    formData.append('status', status);
    if (trouble.length > 0) {
      formData.append('comment', trouble);
      setTrouble('');
    }
    if (status === 'SF' && file1) {
      // @ts-ignore
      for (let x = 0; x < file1.length; x++) {
        formData.append('files', file1[x]);
      }
      setFile1([]);
    } else if (status === 'STS' && file2) {
      // @ts-ignore
      for (let x = 0; x < file2.length; x++) {
        formData.append('files', file2[x]);
      }
      setFile2([]);
    }
    setModalActive(false);
    updateWithdrawal({ body: formData, id: selectedWithdrawal?.id || '' });
    setSelectedId(null);
  };

  const handleSendTrouble = () => {
    setTroubleModalActive(false);
    handleAccept('STS');
  };

  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 7));
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    setPage(1);
  }, [activeWithdrawalsFilter]);

  const handleFileInput1 = (event: ChangeEvent<HTMLInputElement>) => {
    if (file1.length === 4) {
      pushNotify('Вы можете загрузить не более 4 файлов', 'Ошибка');
      return;
    }
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      if (!AcceptedFiles.includes(selectedFiles[0].type)) {
        pushNotify('Недопустимый формат файла', 'Ошибка');
        return;
      }
      if (bytesToMegaBytes(selectedFiles[0].size) > 20) {
        pushNotify('Размер файла не должен превышать 20 МБ', 'Ошибка');
        return;
      }
      setFile1((prev) => [...prev, selectedFiles[0]]);
    }
  };

  const handleFileInput2 = (event: ChangeEvent<HTMLInputElement>) => {
    if (file2.length === 4) {
      pushNotify('Вы можете загрузить не более 4 файлов', 'Ошибка');
      return;
    }
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      if (!AcceptedFiles.includes(selectedFiles[0].type)) {
        pushNotify('Недопустимый формат файла', 'Ошибка');
        return;
      }
      if (bytesToMegaBytes(selectedFiles[0].size) > 20) {
        pushNotify('Размер файла не должен превышать 20 МБ', 'Ошибка');
        return;
      }
      setFile2((prev) => [...prev, selectedFiles[0]]);
    }
  };

  const handleOpenPreview1 = (id: number) => {
    setActiveFile1Id(id);
    setPreview1Active(true);
  };

  const handleOpenPreview = (id: number) => {
    setActiveFileId(id);
    setPreviewActive(true);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.add('dragover');
  };

  const handleDragLeave = (event: any) => {
    event.currentTarget.classList.remove('dragover');
  };

  const handleDrop1 = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.remove('dragover');
    if (file1.length === 4) {
      pushNotify('Вы можете загрузить не более 4 файлов', 'Ошибка');
      return;
    }
    const selectedFiles = event.dataTransfer.files;
    if (!AcceptedFiles.includes(selectedFiles[0].type)) {
      pushNotify('Недопустимый формат файла', 'Ошибка');
      return;
    }
    if (bytesToMegaBytes(selectedFiles[0].size) > 20) {
      pushNotify('Размер файла не должен превышать 20 МБ', 'Ошибка');
      return;
    }
    if (selectedFiles && selectedFiles.length > 0) {
      setFile1((prev) => [...prev, selectedFiles[0]]);
      if (ref1.current) ref1.current.files = selectedFiles;
    }
  };

  const handleDrop2 = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.remove('dragover');
    if (file2.length === 4) {
      pushNotify('Вы можете загрузить не более 4 файлов', 'Ошибка');
      return;
    }
    const selectedFiles = event.dataTransfer.files;
    if (!AcceptedFiles.includes(selectedFiles[0].type)) {
      pushNotify('Недопустимый формат файла', 'Ошибка');
      return;
    }
    if (bytesToMegaBytes(selectedFiles[0].size) > 20) {
      pushNotify('Размер файла не должен превышать 20 МБ', 'Ошибка');
      return;
    }
    if (selectedFiles && selectedFiles.length > 0) {
      setFile2((prev) => [...prev, selectedFiles[0]]);
      if (ref2.current) ref2.current.files = selectedFiles;
    }
  };

  const mapWithdrawalsCallback = useCallback(() => mapWithdrawals(data?.results), [data]);

  const handleTroubleModalOpen = () => {
    setModalActive(false);
    setTroubleModalActive(true);
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', maxWidth: '440px' }}>
        <span
          style={{
            width: '70%',
            display: 'flex',
            marginBottom: '12px',
            alignItems: 'center',
          }}
        >
          Найдите заявку:
        </span>
        <input
          className={styles.input}
          type={'text'}
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
      </div>
      <div style={{ width: '100%', overflow: 'auto', transform: 'rotateX(180deg)' }}>
        <div style={{ transform: 'rotateX(180deg)', width: '100%' }}>
          <Table
            hasPadding
            thArray={withdrawalsThArray}
            tdArray={
              mapWithdrawalsCallback()?.map((withdrawal) => {
                const style = getOrderStyle(withdrawal.statusId);
                return [
                  {
                    element: withdrawal.has_files ? (
                      <a
                        style={{
                          cursor: 'pointer',
                          color: 'blue',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          setSelectedId(withdrawal.id);
                          setWithdrawalPreviewOpen(true);
                        }}
                      >
                        {withdrawal.id}
                      </a>
                    ) : (
                      withdrawal.id
                    ),
                    style,
                  },
                  {
                    element: (
                      <Selector
                        open={openSelector}
                        setOpen={setOpenSelector}
                        id={withdrawal.id}
                        status={withdrawal.status}
                        statusId={withdrawal.statusId}
                        pageName='withdraw'
                        handleEdit={handleEdit}
                        onOpenAcceptModal={(id) => handleOpenModal(id)}
                      />
                    ),
                    style,
                  },
                  { element: withdrawal.payment_method, style },
                  { element: withdrawal.requisite_number, style },
                  { element: withdrawal.bank, style },
                  { element: withdrawal.account_owner, style },
                  { element: withdrawal.date, style },
                  { element: withdrawal.amount_to, style },
                  { element: withdrawal.amount_in, style },
                  {
                    element: Number(withdrawal.time_left)
                      ? fancyTimeFormat(Number(withdrawal.time_left))
                      : '',
                    style,
                  },
                ];
              }) || []
            }
          >
            {' '}
          </Table>
          {createPortal(
            <ModalDefault
              title={'Подтверждение заявки на выплату'}
              titleMargin={'12px'}
              active={modalActive}
              setActive={setModalActive}
            >
              <form onSubmit={() => handleAccept('SF')} className={styles.modalForm}>
                <p>Сделайте перевод на указанный счет и прикрепите квитанцию об оплате</p>
                <div className={styles.modalPart}>
                  <p>Вы отправляете</p>
                  <p>{`${selectedWithdrawal?.amount_to} ${selectedWithdrawal?.currency_amount_to}`}</p>
                </div>
                <div className={styles.modalPart}>
                  <p>Вы получаете</p>
                  <p>{`${formatNumber(Number(selectedWithdrawal?.amount_in), 2)} ${
                    selectedWithdrawal?.currency_amount_in
                  }`}</p>
                </div>
                <div className={styles.modalPart}>
                  <p>Тип счета</p>
                  <p>{selectedWithdrawal?.payment_method}</p>
                </div>
                <div className={styles.modalPart}>
                  <p>Реквизиты</p>
                  <p>{selectedWithdrawal?.requisite_number}</p>
                </div>
                <div className={styles.modalPart}>
                  <p>Банк</p>
                  <p>{selectedWithdrawal?.bank}</p>
                </div>
                {selectedWithdrawal?.account_owner && (
                  <div className={styles.modalPart}>
                    <p>Владелец счета</p>
                    <p>{selectedWithdrawal?.account_owner}</p>
                  </div>
                )}
                <div className={styles.modalPart}>
                  <p>Дата</p>
                  <p>{selectedWithdrawal?.create_date}</p>
                </div>
                <div className={styles.inputWrapper}>
                  <label>Приложите квитанцию:</label>
                  <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop1}
                    onClick={() => ref1.current && ref1.current.click()}
                  >
                    <input
                      onChange={handleFileInput1}
                      ref={ref1}
                      type='file'
                      id='fileInput1'
                      placeholder='Файл не выбран'
                      accept='image/bmp, image/jpg, image/png, image/gif, image/webp, image/jpeg, application/pdf, video/mp4'
                      multiple={false}
                      style={{ display: 'none' }}
                    />
                    <div className={styles.filesDescription}>
                      <p>Выбрать файл</p>
                      <p>
                        Вы можете выбрать до 4 файлов формата bmp, jpg, png, gif, webp, jpeg, pdf,
                        mp4 до 20 МБ
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.files}>
                  {file1.map((file, index) => (
                    <div key={index}>
                      <Button
                        style={{
                          padding: '6px 12px',
                          borderTopRightRadius: 0,
                          border: 'none',
                          borderBottomRightRadius: 0,
                          backgroundColor: '#cccccc',
                          color: 'black',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        type='button'
                        action={() => {
                          handleOpenPreview1(index);
                        }}
                      >
                        <span>{file.name}</span>
                      </Button>
                      <Button
                        style={{
                          backgroundColor: '#474747',
                          border: 'none',
                          padding: '6px',
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        type='button'
                      >
                        <span
                          onClick={() => {
                            setFile1(file1.filter((f) => f.name !== file.name));
                          }}
                        >
                          X
                        </span>
                      </Button>
                    </div>
                  ))}
                </div>
                <div className={styles.modalButtons}>
                  <Button
                    text={'У меня проблема'}
                    action={handleTroubleModalOpen}
                    disabled={false}
                    style={{ backgroundColor: '#FE5B37', border: 'none' }}
                    type='button'
                  />
                  <Button
                    text={'Деньги отправлены'}
                    type={'submit'}
                    disabled={file1.length === 0}
                  />
                </div>
              </form>
            </ModalDefault>,
            document.body,
          )}
          {createPortal(
            <ModalDefault
              title={'У меня проблема'}
              titleMargin={'12px'}
              active={troubleModalActive}
              setActive={setTroubleModalActive}
            >
              <form onSubmit={handleSendTrouble} className={styles.modalForm}>
                <p>Пожалуйста, опишите вашу проблему</p>
                <div className={styles.textareaWrapper}>
                  <ContentEditable
                    onChange={onContentChange}
                    onBlur={onContentChange}
                    html={trouble.slice(0, 1000)}
                    className={styles.textArea}
                    placeholder='Опишите вашу проблему...'
                  />
                  <span className={styles.counter}>{trouble.length}/1000</span>
                </div>
                <div className={styles.inputWrapper}>
                  <p style={{ marginBottom: '12px' }}>
                    Пожалуйста, приложите подтверждающие документы
                  </p>
                  <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop2}
                    onClick={() => ref2.current && ref2.current.click()}
                  >
                    <input
                      onChange={handleFileInput2}
                      ref={ref2}
                      type='file'
                      id='fileInput2'
                      placeholder='Файл не выбран'
                      accept='image/bmp, image/jpg, image/png, image/gif, image/webp, image/jpeg, application/pdf, video/mp4'
                      multiple={false}
                      style={{ display: 'none' }}
                    />
                    <div className={styles.filesDescription}>
                      <p>Выбрать файл</p>
                      <p>
                        Вы можете выбрать до 4 файлов формата bmp, jpg, png, gif, webp, jpeg, pdf,
                        mp4 до 20 МБ
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.files}>
                  {file2.map((file, index) => (
                    <div key={index}>
                      <Button
                        style={{
                          padding: '6px 12px',
                          borderTopRightRadius: 0,
                          border: 'none',
                          borderBottomRightRadius: 0,
                          backgroundColor: '#cccccc',
                          color: 'black',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        action={() => {
                          handleOpenPreview(index);
                        }}
                        type='button'
                      >
                        <span>{file.name}</span>
                      </Button>
                      <Button
                        style={{
                          backgroundColor: '#474747',
                          border: 'none',
                          padding: '6px',
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        type='button'
                      >
                        <span onClick={() => setFile2(file2.filter((f) => f.name !== file.name))}>
                          X
                        </span>
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  type='submit'
                  text={'Отправить в поддержку'}
                  disabled={file2.length === 0 || !trouble || trouble.length === 0}
                />
              </form>
            </ModalDefault>,
            document.body,
          )}

          {fileURLS.length > 0 &&
            createPortal(
              <ModalDefault
                title={'Превью'}
                titleMargin={'12px'}
                active={previewActive}
                setActive={setPreviewActive}
              >
                <div className={styles.preview}>
                  {fileURLS[activeFileId || 0].type === 'image' ? (
                    <img
                      src={fileURLS[activeFileId || 0].url}
                      alt='preview'
                      width={'100%'}
                      height={'100%'}
                    />
                  ) : (
                    <iframe src={fileURLS[activeFileId || 0].url} width={'100%'} height='500px' />
                  )}
                </div>
              </ModalDefault>,
              document.body,
            )}
          {withdrawalFiles &&
            withdrawalFiles.files.length > 0 &&
            createPortal(
              <ModalDefault
                title={'Превью'}
                titleMargin={'12px'}
                active={withdrawalPreviewOpen}
                setActive={setWithdrawalPreviewOpen}
              >
                <div className={styles.previewFiles}>
                  {withdrawalFiles.files
                    .filter((file) => file.file_type === 'picture')
                    .map((file) => (
                      <img
                        src={`${process.env.REACT_APP_AWS_S3_ENDPOINT_URL}${file.url}`}
                        alt='preview'
                        width={'100%'}
                        height={'100%'}
                        key={file.url}
                      />
                    ))}
                </div>
                <div className={styles.previewButtons}>
                  {withdrawalFiles.files
                    .filter((file) => file.file_type !== 'picture')
                    .map((file) => (
                      <Button
                        style={{
                          padding: '6px 12px',
                          border: 'none',
                          backgroundColor: '#cccccc',
                          color: 'black',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        type='button'
                        action={() => handleDownloadFile(file.url, file.file_type)}
                      >
                        <span>{file.file_type}</span>
                      </Button>
                    ))}
                </div>
              </ModalDefault>,
              document.body,
            )}
          {fileURLS1.length > 0 &&
            createPortal(
              <ModalDefault
                title={'Превью'}
                titleMargin={'12px'}
                active={preview1Active}
                setActive={setPreview1Active}
              >
                <div className={styles.preview}>
                  {fileURLS1[activeFile1Id || 0].type === 'image' ? (
                    <img
                      src={fileURLS1[activeFile1Id || 0].url}
                      alt='preview'
                      width={'100%'}
                      height={'100%'}
                    />
                  ) : (
                    <iframe
                      src={fileURLS1[activeFile1Id || 0].url}
                      width={'100%'}
                      height={'500px'}
                    />
                  )}
                </div>
              </ModalDefault>,
              document.body,
            )}
        </div>
      </div>
      <Pagination
        totalPages={data?.page_count || 1}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </React.Fragment>
  );
};

export default WithdrawalsTable;
