import styles from './Selector.module.css';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside';
import clsx from 'clsx';

interface ISelectorProps {
  title: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  blockStyle?: CSSProperties;
  items: TItem[];
  defaultValue?: string;
  disabled?: boolean;
}

export interface TItem {
  name: string;
  id: string;
}

export const Selector: FC<ISelectorProps> = ({
  title,
  items,
  blockStyle,
  name,
  required,
  placeholder,
  defaultValue,
  disabled,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const menuRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
      setSelectedValue(defaultValue);
    }
  }, [defaultValue, name, setValue]);

  useOnClickOutside(menuRef, () => setOpen(false));

  const handleInputClick = (item: TItem) => {
    setSelectedValue(item.name);
    setOpen(false);
  };

  return (
    <div style={blockStyle} ref={menuRef}>
      <div className={styles.title}>
        {required && <span style={{ color: '#FE5B37' }}>*</span>}
        {title}
      </div>
      <div
        className={clsx(styles.selectorHead, {
          [styles.disabled]: disabled,
          [styles.error]: errors?.[name],
        })}
        onClick={() => setOpen((prev) => !prev)}
      >
        {selectedValue ? (
          <div className={styles.selected}>{selectedValue}</div>
        ) : (
          <div className={styles.placeholder}>{placeholder}</div>
        )}
        <img src={open ? '/arrow_up_icon.png' : '/arrow_down_icon.png'} alt={'arrow'} />
      </div>

      {open && !disabled && (
        <div className={styles.selectorWrapper}>
          <div className={styles.selector}>
            {items.map((item) => {
              return (
                <div className={styles.option} key={item.id}>
                  <label htmlFor={item.id}>
                    <input
                      defaultChecked={defaultValue === item.id}
                      type={'radio'}
                      id={item.id}
                      value={item.name || defaultValue}
                      onClick={() => handleInputClick(item)}
                      {...register(name, { value: defaultValue })}
                    />
                    {item.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
