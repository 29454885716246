export const handleDownloadFile = (url: string, type: string) => {
  fetch(`${process.env.REACT_APP_AWS_S3_ENDPOINT_URL}${url}`, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.${type === 'document' ? 'pdf' : 'mp4'}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
};
