import { TWithdrawalsResponse } from '../../../redux/types/WithdrawalsTypes';
import formatNumber from '../formatNumber';
import { getStatusById } from '../getStatusById';
import moment from 'moment';

export type WithdrawalsModel = {
  id: string;
  status: string;
  statusId: string;
  payment_method: string;
  account_owner: string;
  requisite_number: string;
  bank: string;
  date: string;
  amount_to: string;
  amount_in: string;
  time_left: string;
  has_files: boolean;
};

export const mapWithdrawals = (
  withdrawals: TWithdrawalsResponse[] | undefined,
): WithdrawalsModel[] => {
  return (
    withdrawals?.map((item: TWithdrawalsResponse) => ({
      id: item.id,
      status: getStatusById(item.status),
      statusId: item.status,
      payment_method: item.payment_method,
      requisite_number: item.requisite_number,
      bank: item.bank,
      date: moment(item.create_date, 'DD-MM-YYYY HH:mm').format('DD.MM.YY HH:mm'),
      amount_to: `${formatNumber(Number(item.amount_to), 2)} ${item.currency_amount_to}`,
      amount_in: `${formatNumber(Number(item.amount_in), 2)} ${item.currency_amount_in}`,
      time_left: item.time_left,
      account_owner: item.account_owner,
      has_files: item.has_files,
    })) || []
  );
};
