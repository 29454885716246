import { getStatusById } from '../getStatusById';
import { type TOrdersResponse } from '../../../redux/types/OrdersTypes';

export interface OrderModel {
  id: string;
  status: string;
  statusId: string;
  isSelectorStatusActive: boolean;
  card_number: string;
  bank: string;
  expected_income: string;
  real_income: string;
  temp_income?: string;
  date: string;
  fio?: string;
  time_left?: number;
  has_files?: boolean;
}

const mapOrders = (orders: TOrdersResponse[] | undefined): OrderModel[] => {
  return (
    orders?.map((item: TOrdersResponse) => ({
      id: item.id,
      status: getStatusById(item.status),
      statusId: item.status,
      isSelectorStatusActive: false,
      card_number: item.requisite.account_number,
      fio: item.requisite.fio,
      bank: item.requisite.bank.name,
      expected_income: (item.expected_income + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      real_income: (item.real_income + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      temp_income: (item.temp_income + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      date: item.create_dt,
      time_left: item.time_left,
      has_files: item.has_files,
    })) ?? []
  );
};

export default mapOrders;
