import styles from './Description.module.css';
import { Loading } from '../../../../../components/Loading/Loading';
import { useGetSelfQuery } from '../../../../../redux/services/TraderApi';
import formatNumber from '../../../../../utils/functions/formatNumber';

type MoneyStateProps = {
  page?: 'withdrawal' | 'orders';
};

export const MoneyState: React.FC<MoneyStateProps> = ({ page = 'orders' }) => {
  const { data: trader, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <h3 className={styles.title}>Баланс</h3>
        <h4 className={styles.item}>
          USDT
          <span className={styles.value}>
            {formatNumber(
              Number(page === 'withdrawal' ? trader?.balance_withdrawal : trader?.balance),
            )}
          </span>
        </h4>
        {page !== 'withdrawal' && (
          <h4 className={styles.item}>
            {trader?.currency}
            <span className={styles.value}>{formatNumber(Number(trader?.my_balance_in_fiat))}</span>
          </h4>
        )}
      </div>
      <div className={styles.block}>
        <h3 className={styles.title}>Пополнение</h3>
        <h4 className={styles.item}>
          {page === 'withdrawal' ? 'USDT' : 'RUB'}
          <span className={styles.value}>
            {formatNumber(
              Number(
                page === 'withdrawal' ? trader?.total_income_withdrawal : trader?.total_income,
              ),
            )}
          </span>
        </h4>
      </div>
      <div className={styles.block}>
        <h3 className={styles.title}>Выведено</h3>
        <h4 className={styles.item}>
          {trader?.currency}
          <span className={styles.value}>
            {formatNumber(
              Number(
                page === 'withdrawal' ? trader?.total_outcome_withdrawal : trader?.total_outcome,
              ),
            )}
          </span>
        </h4>
      </div>
      <div className={styles.block}>
        <h3 className={styles.title}>Заморожено</h3>
        <h4 className={styles.item}>
          USDT
          <span className={styles.value}>
            {formatNumber(
              Number(
                page === 'withdrawal' ? trader?.frozen_balance_withdrawal : trader?.frozen_balance,
              ),
            )}
          </span>
        </h4>
        {page !== 'withdrawal' && (
          <h4 className={styles.item}>
            {trader?.currency}
            <span className={styles.value}>
              {formatNumber(Number(trader?.my_frozen_balance_in_fiat))}
            </span>
          </h4>
        )}
      </div>
    </div>
  );
};
