import './ChangeDescriptionInput.css';
import { ControlledInput } from '../../../../../../../../components/ControlledInput/ControlledInput';
import { RegisterOptions } from 'react-hook-form';

export interface TEditCardProps {
  id: number | null;
  currentId: number | null;
  defaultValue: string;
  name: string;
  placeholder: string;
  rules?: RegisterOptions;
}

export interface IInputsEditCard {
  newDescription: string;
  newLimit: string;
  newMaxIncome: number;
  newMinIncome: number;
  newInstantOrders: string;
}
export const ChangeDescriptionInput = (props: TEditCardProps) => {
  const { id, currentId, defaultValue, name, placeholder } = props;
  return (
    <>
      {id !== null && currentId !== null && id === currentId ? (
        <ControlledInput
          inputStyle={{
            padding: '4px 6px',
            color: '#2c2e35',
            border: '1px solid #1890ff',
            borderRadius: '2px',
          }}
          placeholder={placeholder}
          name={name}
          type={'text'}
          defaultValue={defaultValue}
          transform={(text: string | undefined) => {
            if (text) {
              return text.slice(0, 100);
            }
          }}
        />
      ) : (
        defaultValue
      )}
    </>
  );
};
