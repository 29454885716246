import React, { FC } from 'react';
import { type TEditCardProps } from '../ChangeDescriptionInput/ChangeDescriptionInput';
import { ControlledInput } from '../../../../../../../../components/ControlledInput/ControlledInput';
import { transformFunction } from '../../../../AddCard/utils';
import { useFormContext } from 'react-hook-form';

export const ChangeIncomeLimitInput: FC<TEditCardProps> = ({
  id,
  currentId,
  defaultValue,
  name,
  placeholder,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {id !== null && currentId !== null && id === currentId ? (
        <ControlledInput
          inputStyle={{
            padding: '4px 6px',
            color: '#2c2e35',
            border: errors[name] ? '1px solid #fe5b37' : '1px solid #1890ff',
            borderRadius: '2px',
          }}
          defaultValue={defaultValue}
          placeholder={placeholder}
          name={name}
          type={'text'}
          rules={{ required: true, maxLength: 13 }}
          transform={transformFunction}
        />
      ) : (
        defaultValue
      )}
    </>
  );
};
