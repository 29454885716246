import styles from './Income.module.css';
import { type OrderModel } from '../../../../../utils/functions/map/mapOrders';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { ModalDefault } from '../../../../../components/ModalDefault/ModalDefault';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { parseStringToNumber } from '../../../../../utils/functions/parseNumber';
import { useUpdateOrderDataMutation } from '../../../../../redux/services/OrdersApi';
import { ControlledInput } from '../../../../../components/ControlledInput/ControlledInput';
import { Button } from '../../../../../components/Button/Button';
import { pushNotify } from 'components/Notifications';
import { AcceptedFiles } from 'utils/constants/text';
import { bytesToMegaBytes } from 'utils/functions/bytesToMEgabytes';
import { createPortal } from 'react-dom';

export interface TIncomeProps {
  order: OrderModel;
}

interface TInputs {
  income: string;
}

const Income = (props: TIncomeProps) => {
  const { order } = props;
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  });
  const { handleSubmit, reset } = formMethods;
  const [file1, setFile1] = useState<File[]>([]);
  const ref1 = useRef<HTMLInputElement>(null);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [updateOrder] = useUpdateOrderDataMutation(undefined);
  const [activeFile1Id, setActiveFile1Id] = useState<number | null>(null);
  const [preview1Active, setPreview1Active] = useState(false);
  const fileURLS1 = useMemo(
    () =>
      file1.map((file) => {
        return {
          url: URL.createObjectURL(file),
          type:
            file.type === 'video/mp4'
              ? 'video'
              : file.type === 'application/pdf'
              ? 'document'
              : 'image',
        };
      }),
    [file1],
  );

  const onSubmit: SubmitHandler<TInputs> = async (data: any) => {
    const formData = new FormData();

    formData.append('status', 'UR');
    formData.append('temp_income', parseStringToNumber(data.income).toString());

    if (file1.length > 0) {
      for (let x = 0; x < file1.length; x++) {
        formData.append('files', file1[x]);
      }
    }

    updateOrder({ body: formData, id: order.id })
      .unwrap()
      .catch((e) => {
        if (e.data && e.data.temp_income) {
          e.data.temp_income.forEach((err: string) => pushNotify(err, 'Ошибка'));
        }
      });

    setFile1([]);
    setModalActive(false);
    reset();
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.add('dragover');
  };

  const handleDragLeave = (event: any) => {
    event.currentTarget.classList.remove('dragover');
  };

  const handleDrop1 = (event: any) => {
    event.preventDefault();
    event.currentTarget.classList.remove('dragover');
    if (file1.length === 4) {
      pushNotify('Вы можете загрузить не более 4 файлов', 'Ошибка');
      return;
    }
    const selectedFiles = event.dataTransfer.files;
    if (!AcceptedFiles.includes(selectedFiles[0].type)) {
      pushNotify('Недопустимый формат файла', 'Ошибка');
      return;
    }
    if (bytesToMegaBytes(selectedFiles[0].size) > 20) {
      pushNotify('Размер файла не должен превышать 20 МБ', 'Ошибка');
      return;
    }
    if (selectedFiles && selectedFiles.length > 0) {
      setFile1((prev) => [...prev, selectedFiles[0]]);
      if (ref1.current) ref1.current.files = selectedFiles;
    }
  };

  const handleFileInput1 = (event: ChangeEvent<HTMLInputElement>) => {
    if (file1.length === 4) {
      pushNotify('Вы можете загрузить не более 4 файлов', 'Ошибка');
      return;
    }
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      if (!AcceptedFiles.includes(selectedFiles[0].type)) {
        pushNotify('Недопустимый формат файла', 'Ошибка');
        return;
      }
      if (bytesToMegaBytes(selectedFiles[0].size) > 20) {
        pushNotify('Размер файла не должен превышать 20 МБ', 'Ошибка');
        return;
      }
      setFile1((prev) => [...prev, selectedFiles[0]]);
    }
  };

  const handleOpenPreview1 = (id: number) => {
    setActiveFile1Id(id);
    setPreview1Active(true);
  };

  if (order.statusId === 'IP') {
    return (
      <FormProvider {...formMethods}>
        <p className={styles.button} onClick={() => setModalActive(true)}>
          Пересчитать
        </p>
        {createPortal(
          <ModalDefault
            title={'Пересчитать'}
            active={modalActive}
            setActive={setModalActive}
            titleMargin={'24px'}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledInput
                title={'Пришло'}
                placeholder={'Введите сумму'}
                name={'income'}
                type={'text'}
                rules={{ required: true, maxLength: 13 }}
                blockStyle={{ marginBottom: '16px' }}
                transform={(text: string | undefined) => {
                  if (text) {
                    return text
                      .replace(/[^0-9.,]|(?<=[.,].*)([.,])/g, '')
                      .replace(',', '.')
                      .slice(0, 13);
                  }
                  return text;
                }}
              />
              <div className={styles.inputWrapper}>
                <p style={{ fontSize: '14px', marginBottom: '4px' }}>Приложите квитанцию:</p>
                <div
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop1}
                  onClick={() => ref1.current && ref1.current.click()}
                >
                  <input
                    onChange={handleFileInput1}
                    ref={ref1}
                    type='file'
                    id='fileInput1'
                    placeholder='Файл не выбран'
                    accept='image/bmp, image/jpg, image/png, image/gif, image/webp, image/jpeg, application/pdf, video/mp4'
                    multiple={false}
                    style={{ display: 'none' }}
                  />
                  <div className={styles.filesDescription}>
                    <p>Выбрать файл</p>
                    <p>
                      Вы можете выбрать до 4 файлов формата bmp, jpg, png, gif, webp, jpeg, pdf, mp4
                      до 20 МБ
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.files}>
                {file1.map((file, index) => (
                  <div key={index}>
                    <Button
                      style={{
                        padding: '6px 12px',
                        borderTopRightRadius: 0,
                        border: 'none',
                        borderBottomRightRadius: 0,
                        backgroundColor: '#cccccc',
                        color: 'black',
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      type='button'
                      action={() => {
                        handleOpenPreview1(index);
                      }}
                    >
                      <span>{file.name}</span>
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#474747',
                        border: 'none',
                        padding: '6px',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      type='button'
                    >
                      <span
                        onClick={() => {
                          setFile1(file1.filter((f) => f.name !== file.name));
                        }}
                      >
                        X
                      </span>
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                text={'Отправить'}
                type={'submit'}
                disabled={file1?.length === 0 || formMethods.getValues('income')?.length === 0}
              />
            </form>
          </ModalDefault>,
          document.body,
        )}
        {fileURLS1?.length > 0 &&
          createPortal(
            <ModalDefault
              title={'Превью'}
              titleMargin={'12px'}
              active={preview1Active}
              setActive={setPreview1Active}
            >
              <div className={styles.preview}>
                {fileURLS1[activeFile1Id || 0].type === 'image' ? (
                  <img
                    src={fileURLS1[activeFile1Id || 0].url}
                    style={{ width: '100%', height: '100%', maxWidth: 'none' }}
                    alt='preview'
                    width={'100%'}
                    height={'100%'}
                  />
                ) : (
                  <iframe src={fileURLS1[activeFile1Id || 0].url} width={'100%'} height={'500px'} />
                )}
              </div>
            </ModalDefault>,
            document.body,
          )}
      </FormProvider>
    );
  }
  if (order.statusId === 'UR') {
    return (
      <p className={styles.notOk}>
        {order.temp_income}
        <span>Не подтвержден</span>
      </p>
    );
  }
  if (order.statusId === 'FR') {
    return (
      <p className={styles.ok}>
        {order.real_income}
        <span>Одобрено</span>
      </p>
    );
  }
  if (order.statusId === 'CR') {
    return (
      <p className={styles.declined}>
        {order.real_income}
        <span>Отклонено</span>
      </p>
    );
  }
  return <>{order.real_income}</>;
};

export default Income;
