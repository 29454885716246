import React from 'react';
import './Table.css';
import { type TableProps } from '../../utils/interfaces';
export const Table: React.FC<TableProps> = ({ thArray, tdArray, hasPadding = false }) => {
  return (
    <div style={{ marginBottom: '16px', overflowY: hasPadding ? 'auto' : 'unset' }}>
      <table className={'table'}>
        <thead>
          <tr className={'column_header'}>
            {thArray.map((th, index) => {
              return (
                <th style={th.style} key={index}>
                  {th.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tdArray.length === 0 ? (
            <tr>
              <td className={'empty_table'} colSpan={thArray.length}>
                <img src={'/no_data_icon.png'} alt={'no data icon'} />
              </td>
            </tr>
          ) : (
            tdArray.map((a, index) => {
              return (
                <tr key={index}>
                  {a.map((b, index) => {
                    return (
                      <td style={b.style} key={index}>
                        {' '}
                        {b.element}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
