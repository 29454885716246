import styles from './withdrawal.module.css';
import { useRecoilState } from 'recoil';
import { Content } from '../components/Content/Content';
import { defaultActiveWithdrawalsFilter } from '../../../../../utils/states/activeFilter.state';
import { MainContainer } from '../../../../../components/MainContainer/MainContainer';
import { Rate } from '../../components/Rate/Rate';
import { MoneyState } from '../../components/MoneyState/MoneyState';
import { TimeArea } from '../../components/Timearea/TimeArea';
import { Filter } from '../../components/Filter/Filter';
import { withdrawalsFilters } from '../../../../../utils/constants/filters';

const Withdrawal = () => {
  const [{ activeWithdrawalsFilter }, changeFilter] = useRecoilState(
    defaultActiveWithdrawalsFilter,
  );
  const handleSetFilter = (filter: string) => changeFilter({ activeWithdrawalsFilter: filter });
  return (
    <MainContainer>
      <div style={{ height: '100%', position: 'relative' }}>
        <Rate />
        <MoneyState page='withdrawal' />
        <h3 className={styles.title}>Вывод</h3>
        <TimeArea />
        <Filter
          activeFilter={activeWithdrawalsFilter}
          changeFilter={handleSetFilter}
          filters={withdrawalsFilters}
        />
        <Content />
      </div>
    </MainContainer>
  );
};

export default Withdrawal;
