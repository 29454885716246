export const statusIds = [
  'BN',
  'WP',
  'IP',
  'TO',
  'MV',
  'SF',
  'FR',
  'NF',
  'AA',
  'UR',
  'CR',
  'SF,AA',
  'ID',
  'CR',
  'AAD',
  'CANCELED',
  'STS',
  'PC',
];
export const statusesTranslate = [
  'Новая заявка',
  'Ожидают платежа',
  'В процессе',
  'Просроченные',
  'Ожидает проверки админом',
  'Завершена успешно',
  'Завершенные с перерасчетом',
  'Заявка не найдена',
  'Автоматически одобрена',
  'Заявка с перерасчетом',
  'Перерасчет отклонен',
  'Завершена успешно',
  'Спор',
  'Отказ',
  'Автоматически одобрена из диспута',
  'Отказ',
  'Отправлена в поддержку',
  'Проблема подтверждена',
];

export const statuses = [
  'BRAND_NEW',
  'IN_PROGRESS',
  'TIME_OUT',
  'SUCCESSFULLY_FINISHED',
  'FINISHED_WITH_RECALCULATION',
  'REQUISITES_NOT_FOUND',
  'AUTO_APPROVED',
  'UNFINISHED_WITH_RECALCULATION',
  'CANCELED_WITH_RECALCULATION',
  'IN_DISPUTE',
];
