import { FC, useEffect, useState } from 'react';
import { SwitchButton } from '../components/SwitchButton/SwitchButton';
import { ChangeDescriptionInput } from '../components/ChangeDescriptionInput/ChangeDescriptionInput';
import { ChangeLimitInput } from '../components/ChangeLimitInput/ChangeLimitInput';
import { ActionButtons } from '../components/ActionButtons/ActionButtons';
import { type CardsModel } from '../../../../../../../utils/types';
import { useGetCardsQuery } from '../../../../../../../redux/services/CardsApi';
import mapCards from '../../../../../../../utils/functions/map/mapCards';
import calculateElements from '../../../../../../../utils/functions/calcucateElements';
import { Loading } from '../../../../../../../components/Loading/Loading';
import { cardThArray } from '../../../../../../../utils/constants/th_arrays';
import { Pagination } from '../../../../../../../components/Pagination/Pagination';
import { type TCardsContentProps } from '../../CardsContent';
import { PaginationStyles, StyledTable, TableWrapperStyles } from './styles';
import { ChangeIncomeLimitInput } from '../components/ChangeIncomeLimitInput/ChangeIncomeLimitInput';

export const CardsTable: FC<TCardsContentProps> = ({ currentId, setCurrentId }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading } = useGetCardsQuery({ page, page_size: pageSize });

  const handleResize = () => {
    setPageSize(calculateElements([450, 480, 510, 550, 570, 600, 650], 10));
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <TableWrapperStyles>
        <StyledTable>
          <thead>
            <tr>
              {cardThArray.map((th, index) => (
                <th
                  key={index}
                  style={{
                    ...th.style,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    maxWidth: '250px',
                    backgroundColor: '#e2e5f0',
                    fontWeight: '400',
                  }}
                >
                  {th.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mapCards(data?.results)?.map((card: CardsModel) => (
              <tr key={card.id}>
                <td>
                  <SwitchButton active={card.active} id={card.id} />
                </td>
                <td>{card.tag}</td>
                <td>
                  <ChangeDescriptionInput
                    id={card.id}
                    currentId={currentId}
                    defaultValue={card.description}
                    placeholder={''}
                    name={'newDescription'}
                  />
                </td>
                <td>{card.payment_type}</td>
                <td>
                  {card.payment_type === 'C2C' || card.payment_type === 'SBP' ? (
                    card.account_number
                  ) : (
                    <>-</>
                  )}
                </td>
                <td>{card.payment_type === 'TRANSFER' ? card.account_number : <>-</>}</td>
                <td>{card.bank.bic ?? <>-</>}</td>
                <td>{card.bank.name}</td>
                <td>{card.payment_type === 'TRANSFER' ? 'Перевод' : '-'}</td>
                <td>{card.phone_number}</td>
                <td>
                  <ChangeLimitInput
                    currentId={currentId}
                    id={card.id}
                    defaultValue={card.total_limit}
                    name='newLimit'
                    placeholder={'Лимит карты'}
                  />
                </td>
                <td>{card.fio}</td>
                <td>{card.total_income}</td>
                <td>
                  <ChangeIncomeLimitInput
                    currentId={currentId}
                    id={card.id}
                    defaultValue={String(card.min_income)}
                    name='newMinIncome'
                    placeholder={''}
                  />
                </td>
                <td>
                  <ChangeIncomeLimitInput
                    currentId={currentId}
                    id={card.id}
                    defaultValue={String(card.max_income)}
                    name='newMaxIncome'
                    placeholder={''}
                  />
                </td>
                <td>
                  <ChangeLimitInput
                    currentId={currentId}
                    id={card.id}
                    defaultValue={String(card.instant_active_orders)}
                    name='newInstantOrders'
                    placeholder={''}
                    rules={{ min: 1, max: 100000000, maxLength: 11 }}
                  />
                </td>
                <td>
                  <ActionButtons currentId={currentId} setCurrentId={setCurrentId} id={card.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapperStyles>
      <PaginationStyles>
        <Pagination
          totalPages={Math.ceil((data?.count || 0) / pageSize)}
          setCurrentPage={setPage}
          currentPage={page}
          nextPage={data?.next || page}
          previousPage={data?.previous || page}
        />
      </PaginationStyles>
    </>
  );
};
