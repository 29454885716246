import { ControlledInput } from 'components/ControlledInput/ControlledInput';
import styles from './styles.module.css';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'components/Button/Button';
import { useProcessPaymentMutation } from 'redux/services/PaymentsApi';

type UpBalance = {
  amount: number;
  trader_id: number;
};

const UpBalance = () => {
  const formMethods = useForm<UpBalance>();
  const [addBalance] = useProcessPaymentMutation();

  const onSubmit = (data: UpBalance) => {
    addBalance(data);
  };

  return (
    <FormProvider {...formMethods}>
      <div className={styles.root}>
        <form className={styles.form}>
          <ControlledInput type='text' name='amount' placeholder='Введите сумму' />
          <ControlledInput type='text' name='trader_id' placeholder='Введите id трейдера' />
          <Button text='Пополнить' type='submit' action={formMethods.handleSubmit(onSubmit)} />
        </form>
      </div>
    </FormProvider>
  );
};

export default UpBalance;
