import { useRecoilState } from 'recoil';
import { defaultActiveOrdersFilter } from '../../../../../../../../utils/states/activeFilter.state';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
  useGetOrderFilesQuery,
  useGetOrdersQuery,
  useUpdateOrderStatusMutation,
} from '../../../../../../../../redux/services/OrdersApi';
import calculateElements from '../../../../../../../../utils/functions/calcucateElements';
import { getOrderStyle, ordersThArray } from '../../../../../../../../utils/constants/th_arrays';
import mapOrders, { OrderModel } from '../../../../../../../../utils/functions/map/mapOrders';
import { Table } from '../../../../../../../../components/Table/Table';
import Income from '../../../../../components/Income/Income';
import { Selector } from '../../../../../components/Selector/Selector';
import { Pagination } from '../../../../../../../../components/Pagination/Pagination';
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault';
import styles from '../../../../../components/Filter/Filter.module.css';
import { pushNotify } from '../../../../../../../../components/Notifications';
import { createPortal } from 'react-dom';

import * as UI from './styles';
import { Button } from 'components/Button/Button';
import { handleDownloadFile } from 'utils/functions/downloadFile';

const getStyle = (baseStyle: CSSProperties): CSSProperties => ({
  ...baseStyle,
  padding: baseStyle.padding || '0',
  wordBreak: 'normal' as CSSProperties['wordBreak'],
});

const LinkStatuses = ['ID', 'FR', 'UR', 'AAD', 'CR'];

export const OrderTable = () => {
  const [{ activeOrdersFilter }] = useRecoilState(defaultActiveOrdersFilter);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(6);
  const [openSelector, setOpenSelector] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [orderUid, setOrderUid] = useState<string | null>(null);
  const [searchId, setSearchId] = useState<string>();
  const [prevCount, setPrevCount] = useState<number | null | undefined>();
  const { data } = useGetOrdersQuery(
    { status: activeOrdersFilter, page, page_size: pageSize, id: searchId?.trim() || '' },
    { pollingInterval: 1000 },
  );
  const { data: ordersFiles } = useGetOrderFilesQuery({ id: orderUid || '' }, { skip: !orderUid });
  const { data: disputes } = useGetOrdersQuery(
    { status: 'ID', page: undefined, page_size: undefined },
    { pollingInterval: 1000 },
  );
  const [updateOrder] = useUpdateOrderStatusMutation();

  const handleEdit = async (newStatus: string, id: string) => {
    const body = { status: newStatus };
    await updateOrder({ body, id }).unwrap();
  };

  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 6));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [activeOrdersFilter]);

  useEffect(() => {
    if (disputes && prevCount === undefined) {
      setPrevCount(disputes.count);
    }
  }, [disputes, prevCount]);

  useEffect(() => {
    if (disputes && prevCount !== null && prevCount !== undefined) {
      if (disputes.count > prevCount) {
        pushNotify(`НОВЫЙ СПОР`, 'ВНИМАНИЕ, НОВЫЙ СПОР');
        setPrevCount(disputes.count);
      }
      setPrevCount(disputes.count);
    }
  }, [disputes]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const renderOrderRow = (order: OrderModel) => {
    const style = getOrderStyle(order.statusId);
    const linkStyle: CSSProperties = {
      cursor: 'pointer',
      color: 'blue',
      textDecoration: 'underline',
    };

    return [
      {
        element:
          LinkStatuses.includes(order.statusId) && order.has_files ? (
            <a
              style={linkStyle}
              onClick={() => {
                setOpenModal(true);
                setOrderUid(order.id);
              }}
            >
              {order.id}
            </a>
          ) : (
            order.id
          ),
        style,
      },
      {
        element: (
          <Selector
            id={order.id}
            statusId={order.statusId}
            status={order.status}
            handleEdit={handleEdit}
            open={openSelector}
            setOpen={setOpenSelector}
            needRecalculation
          />
        ),
        style: getStyle(style),
      },
      {
        element: (
          <div>
            {order.fio} {order.card_number}
          </div>
        ),
        style,
      },
      { element: order.bank, style },
      { element: order.expected_income, style },
      {
        element: order.statusId === 'ID' ? <>{order.temp_income}</> : <Income order={order} />,
        style,
      },
      {
        element: order.date,
        style: getStyle({ ...style, padding: '10px' }),
      },
      { element: order.time_left ? formatTime(order.time_left) : '', style },
    ];
  };

  return (
    <React.Fragment>
      <UI.SearchContainer>
        <UI.SearchLabel>Найдите заявку</UI.SearchLabel>
        <UI.Input
          type='text'
          value={searchId}
          onChange={(event) => setSearchId(event.target.value)}
        />
      </UI.SearchContainer>
      <div style={{ width: '100%', overflow: 'auto', transform: 'rotateX(180deg)' }}>
        <div style={{ transform: 'rotateX(180deg)' }}>
          {ordersFiles &&
            ordersFiles?.files?.length > 0 &&
            createPortal(
              <ModalDefault
                title={'Превью'}
                titleMargin={'12px'}
                active={openModal}
                setActive={setOpenModal}
              >
                <div className={styles.previewFiles}>
                  {ordersFiles.files
                    .filter((file) => file.file_type === 'picture')
                    .map((file) => (
                      <img
                        src={`${process.env.REACT_APP_AWS_S3_ENDPOINT_URL}${file.url}`}
                        alt='preview'
                        width={'100%'}
                        height={'100%'}
                        key={file.url}
                      />
                    ))}
                </div>
                <div className={styles.previewButtons}>
                  {ordersFiles.files
                    .filter((file) => file.file_type !== 'picture')
                    .map((file) => (
                      <Button
                        style={{
                          padding: '6px 12px',
                          border: 'none',
                          backgroundColor: '#cccccc',
                          color: 'black',
                          maxWidth: '300px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        type='button'
                        action={() => handleDownloadFile(file.url, file.file_type)}
                      >
                        <span>{file.file_type}</span>
                      </Button>
                    ))}
                </div>
              </ModalDefault>,
              document.body,
            )}
          <Table
            hasPadding
            thArray={ordersThArray}
            tdArray={
              mapOrders(data?.results)
                .sort((a: OrderModel, b: OrderModel) => {
                  if (a.statusId === 'ID' && b.statusId !== 'ID') {
                    return -1;
                  }
                  if (a.statusId !== 'ID' && b.statusId === 'ID') {
                    return 1;
                  }
                  return 0;
                })
                ?.map(renderOrderRow) || []
            }
          />
        </div>
      </div>
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        currentPage={page}
        setCurrentPage={setPage}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </React.Fragment>
  );
};
