import { useRecoilState } from 'recoil';
import styles from './ModalDefault.module.css';
import React, { type ReactNode } from 'react';
import { defaultIsMobile } from 'utils/states/isMobile.state';
import clsx from 'clsx';

interface IModalDefault {
  imgTitle?: string;
  imgStyles?: React.CSSProperties;
  title: string;
  titleMargin?: string;
  text?: string;
  textMargin?: string;
  active: boolean;
  setActive: (value: boolean) => void;
  children?: ReactNode;
  windowStyle?: React.CSSProperties;
}
export const ModalDefault = (props: IModalDefault) => {
  const {
    imgTitle,
    imgStyles,
    title,
    titleMargin,
    text,
    textMargin,
    active,
    setActive,
    children,
    windowStyle,
  } = props;
  const [version] = useRecoilState(defaultIsMobile);
  const isMobile = version.isMobile;

  if (!active) {
    return null;
  }
  return (
    <div
      className={clsx(styles.background, { [styles.mobile]: isMobile })}
      onClick={() => setActive(false)}
    >
      <div className={styles.wrapper} style={windowStyle} onClick={(e) => e.stopPropagation()}>
        <div className={styles.content}>
          <img
            className={styles.cross}
            src='/cross_icon.png'
            alt={'cross_icon'}
            onClick={() => {
              setActive(false);
            }}
          />
          {imgTitle && (
            <img src={imgTitle} alt={imgTitle} style={imgStyles} className={styles.imgTitle} />
          )}
          <h3 style={{ marginBottom: titleMargin }}>{title}</h3>
          <p style={{ marginBottom: textMargin }}>{text}</p>
          {children}
        </div>
      </div>
    </div>
  );
};
