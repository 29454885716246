import React, { useCallback, useRef } from 'react';
import styles from './Selector.module.css';
import { useOnCloseOutside } from './hooks/useOnCloseOutside';
import { IOrdersSelectorProps } from './types';

export const Selector = (props: IOrdersSelectorProps) => {
  const {
    id,
    status,
    statusId,
    handleEdit,
    needRecalculation = false,
    setOpen,
    onOpenAcceptModal,
    open,
    pageName,
  } = props;
  const selectorRef = useRef<HTMLDivElement>(null);

  const handleSwitch = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      setOpen((prevId) => (prevId === id ? null : id));
    },
    [id, setOpen],
  );

  const handleClose = useCallback(() => {
    setOpen(null);
  }, [setOpen]);

  const isOpen = open === id;

  useOnCloseOutside(selectorRef, handleClose, 'mousedown', isOpen);

  if (statusId === 'IP' && pageName !== 'withdraw') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.none}>
          <span>{status}</span>
        </div>
      </div>
    );
  }

  if (statusId !== 'BN' && statusId !== 'ID' && statusId !== 'IP') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.none}>
          <span>{status}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} ref={selectorRef}>
      <div className={`${styles.head} ${styles.select_active}`} onClick={handleSwitch}>
        {status}
        <img src={isOpen ? '/arrow_up_icon.png' : '/arrow_down_icon.png'} alt={'arrow icon'} />
      </div>
      {isOpen && (
        <ul id={id} onClick={(e) => e.stopPropagation()}>
          <li
            onClick={(event) => {
              event.stopPropagation();
              if (pageName === 'withdraw' && onOpenAcceptModal) {
                if (statusId === 'BN') {
                  handleEdit('IP', id);
                }
                if (statusId === 'ID') {
                  handleEdit('FR', id);
                }
                if (statusId !== 'ID') {
                  onOpenAcceptModal(id);
                }
              } else {
                handleEdit(statusId === 'ID' ? 'FR' : 'SF', id);
              }
              handleClose();
            }}
          >
            {pageName === 'withdraw'
              ? statusId === 'BN'
                ? 'Принять'
                : statusId === 'ID'
                ? 'Завершена успешно'
                : 'Подтвердить'
              : 'Завершена успешно'}
          </li>
          {statusId !== 'IP' && (
            <li
              onClick={(event) => {
                event.stopPropagation();
                handleEdit(pageName === 'withdraw' ? 'CANCELED' : 'CR', id);
                handleClose();
              }}
            >
              {pageName === 'withdraw' ? (statusId === 'ID' ? 'Отказ' : 'Отклонить') : 'Отказ'}
            </li>
          )}
          {needRecalculation && statusId !== 'ID' && (
            <li
              onClick={(e) => {
                e.stopPropagation();
                handleEdit('IP', id);
                handleClose();
              }}
            >
              Заявка с перерасчетом
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
