export const MANAGER_BOT = 'https://t.me/HELP_HUNTERPAY';

export const phoneRegex = /^\+?[1-9][0-9]{7,14}$/;

export const fancyTimeFormat = (duration: number) => {
  const minutes = ~~((duration % 3600) / 60);
  const seconds = ~~duration % 60;
  let result = '';

  result += '' + minutes + ':' + (seconds < 10 ? '0' : '');
  result += '' + seconds;

  return result;
};

export const AcceptedFiles = [
  'image/bmp',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/jpeg',
  'application/pdf',
  'video/mp4',
];

export const ImageFiles = AcceptedFiles.filter((file) => file.includes('image'));
