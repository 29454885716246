import styles from './Header.module.css';
import React from 'react';
import { useGetSelfQuery } from '../../../../../redux/services/TraderApi';
import { Loading } from '../../../../../components/Loading/Loading';

export const Header = () => {
  const { data: trader, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 });
  const address = trader?.blockchain_address;

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <h3 className={styles.title}>Пополнение счета</h3>
      <div className={styles.block}>
        Адрес: <p>{address}</p>
      </div>
    </div>
  );
};
