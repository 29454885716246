import { useRecoilState } from 'recoil';
import { defaultActiveOrdersFilter } from '../../../../../../../../utils/states/activeFilter.state';
import React, { useEffect, useRef, useState } from 'react';
import {
  useGetOrdersQuery,
  useUpdateOrderStatusMutation,
} from '../../../../../../../../redux/services/OrdersApi';
import mapOrders, { OrderModel } from '../../../../../../../../utils/functions/map/mapOrders';
import { Selector } from '../../../../../components/Selector/Selector';
import Income from '../../../../../components/Income/Income';
import { Pagination } from '../../../../../../../../components/Pagination/Pagination';
import { Blocks } from '../../../../../../../../components/Blocks/Blocks';
import { useGetDisputeByIdQuery } from '../../../../../../../../redux/services/DisputesApi';
import { Loading } from '../../../../../../../../components/Loading/Loading';
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault';
import styles from '../../../../../components/Filter/Filter.module.css';
import { useGetFilesQuery } from 'redux/services/WithdrawalsApi';
import { Button } from 'components/Button/Button';
import { handleDownloadFile } from 'utils/functions/downloadFile';

export const OrderBlocks = () => {
  const [{ activeOrdersFilter }] = useRecoilState(defaultActiveOrdersFilter);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(7);
  const [openSelector, setOpenSelector] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [orderUid, setOrderUid] = useState<string>('');
  const [searchId, setSearchId] = useState<string>();
  const { data } = useGetOrdersQuery(
    { status: activeOrdersFilter, page: page, page_size: pageSize, id: searchId?.trim() || '' },
    { pollingInterval: 1000 },
  );
  const { data: disputes, isLoading: isDisputeLoading } = useGetDisputeByIdQuery(orderUid, {
    skip: orderUid === '',
  });
  const { data: withdrawalFiles } = useGetFilesQuery(
    { id: orderUid || '' },
    { skip: !orderUid, refetchOnMountOrArgChange: true },
  );
  const orders = mapOrders(data?.results) || [];
  const [updateOrder] = useUpdateOrderStatusMutation();
  const handleEdit = async (newStatus: string, id: string) => {
    const body = {
      status: newStatus,
    };
    await updateOrder({ body, id })
      .unwrap()
      .then(() => {});
  };
  useEffect(() => {
    setPage(1);
  }, [activeOrdersFilter]);
  if (isDisputeLoading) return <Loading />;
  return (
    <>
      <div style={{ width: '100%', display: 'flex', maxWidth: '440px' }}>
        <span
          style={{
            width: '70%',
            display: 'flex',
            marginBottom: '12px',
            alignItems: 'center',
          }}
        >
          Найдите заявку:
        </span>
        <input
          className={styles.input}
          type={'text'}
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
      </div>
      <Blocks
        blocks={
          orders
            ?.sort((a: OrderModel, b: OrderModel) => {
              if (a.statusId === 'ID' && b.statusId !== 'ID') {
                return -1;
              }
              if (a.statusId !== 'ID' && b.statusId === 'ID') {
                return 1;
              }
              return 0;
            })
            .map((order) => {
              return (
                <div
                  className={'block'}
                  key={order.id}
                  style={
                    order.statusId === 'BN'
                      ? { background: '#F3FFF4' }
                      : order.statusId === 'ID'
                      ? { background: 'rgba(246,86,86,0.41)' }
                      : {}
                  }
                >
                  <div className={'component'}>
                    <p className={'block-component'}>ID:</p>
                    <p className={'block-value'}>
                      {order.statusId === 'ID' ? (
                        <>
                          <a
                            style={{
                              cursor: 'pointer',
                              color: '#1890FF',
                              textDecorationLine: 'underline',
                            }}
                            onClick={() => {
                              setOpenModal((prevState) => !prevState);
                              setOrderUid(order.id);
                            }}
                          >
                            {order.id}
                          </a>
                        </>
                      ) : (
                        order.id
                      )}
                    </p>
                  </div>
                  <div className={'component'}>
                    <p className={'block-component'}>Статус:</p>
                    <p className={'block-value'}>
                      <Selector
                        open={openSelector}
                        setOpen={setOpenSelector}
                        id={order.id}
                        status={order.status}
                        statusId={order.statusId}
                        handleEdit={handleEdit}
                        needRecalculation={true}
                      />
                    </p>
                  </div>
                  <div className={'component'}>
                    <p className={'block-component'}>Реквизиты:</p>
                    <p className={'block-value'}>
                      <div>
                        <div>{order.fio}</div> {order.card_number}
                      </div>
                    </p>
                  </div>
                  <div className={'component'}>
                    <p className={'block-component'}>Банк:</p>
                    <p className={'block-value'}>{order.bank}</p>
                  </div>
                  <div className={'component'}>
                    <p className={'block-component'}>Ожидается:</p>
                    <p className={'block-value'}>{order.expected_income}</p>
                  </div>
                  <div className={'component'}>
                    <p className={'block-component'}>Пришло:</p>
                    <p className={'block-value'}>
                      {order.statusId === 'ID' ? (
                        <>{order.temp_income}</>
                      ) : (
                        <Income order={order} />
                      )}
                    </p>
                  </div>
                  <div className={'component'}>
                    <p className={'block-component'}>Дата:</p>
                    <p className={'block-value'}>{order.date}</p>
                  </div>
                </div>
              );
            }) || []
        }
      />
      {withdrawalFiles && withdrawalFiles.files.length > 0 && (
        <ModalDefault
          title={'Превью'}
          titleMargin={'12px'}
          active={openModal}
          setActive={setOpenModal}
        >
          <div className={styles.previewFiles}>
            {withdrawalFiles.files
              .filter((file) => file.file_type === 'picture')
              .map((file) => (
                <img
                  src={`${process.env.REACT_APP_AWS_S3_ENDPOINT_URL}${file.url}`}
                  alt='preview'
                  width={'100%'}
                  height={'100%'}
                  key={file.url}
                />
              ))}
          </div>
          <div className={styles.previewButtons}>
            {withdrawalFiles.files
              .filter((file) => file.file_type !== 'picture')
              .map((file) => (
                <Button
                  style={{
                    padding: '6px 12px',
                    border: 'none',
                    backgroundColor: '#cccccc',
                    color: 'black',
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  type='button'
                  action={() => handleDownloadFile(file.url, file.file_type)}
                >
                  <span>{file.file_type}</span>
                </Button>
              ))}
          </div>
        </ModalDefault>
      )}
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </>
  );
};
