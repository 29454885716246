import clsx from 'clsx';
import styles from './BaseInput.module.css';
import React from 'react';
import { type RegisterOptions, useFormContext } from 'react-hook-form';

export interface IInputProps {
  title?: string;
  placeholder: string;
  name: string;
  rules?: RegisterOptions;
  type?: 'password' | 'text';
  imgLeft?: string;
  imgLeftWidth?: number;
  imgRight?: string;
  imgRightWidth?: number;
  imgRightAction?: () => void;
  required?: boolean;
  blockStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  defaultValue?: string;
  errorText?: string;
  disabled?: boolean;
}

export const BaseInput = (props: IInputProps) => {
  const {
    title = '',
    placeholder,
    name,
    rules = {},
    type = 'text',
    imgRight,
    imgRightWidth = 0,
    imgRightAction,
    imgLeft,
    imgLeftWidth = 0,
    required = false,
    blockStyle,
    disabled,
    inputStyle,
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.inputBlock} style={blockStyle}>
      <div className={styles.title}>
        {required && <span style={{ color: '#FE5B37' }}>*</span>}
        {title}
      </div>
      <div className={styles.input}>
        {imgLeft && (
          <img
            src={imgLeft}
            className={styles.imgLeft}
            style={{ width: imgLeftWidth }}
            alt={imgLeft}
          />
        )}
        <input
          style={
            imgLeft ? { ...inputStyle, paddingLeft: `${imgLeftWidth + 22}px` } : { ...inputStyle }
          }
          className={errors?.[name] ? `${styles.field} ${styles.error}` : styles.field}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          {...register(name, rules)}
        />
        {imgRight && (
          <img
            src={imgRight}
            className={styles.imgRight}
            style={{ width: imgRightWidth }}
            onClick={imgRightAction}
            alt={imgRight}
          />
        )}
      </div>
    </div>
  );
};
