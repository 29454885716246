import './Cards.css';
import { CardsSwitcher } from '../components/CardsSwitcher/CardsSwitcher';
import { AddCard } from '../components/AddCard/AddCard';
import { CardsContent } from '../components/Content/CardsContent';
import RequisiteContainer from '../../../../components/RequisiteContainer';
import { useGetSelfQuery } from 'redux/services/TraderApi';
import { Loading } from 'components/Loading/Loading';

export const Cards = () => {
  const { data: _, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 });

  return isLoading ? (
    <Loading />
  ) : (
    <RequisiteContainer>
      <div className={'cards'} style={{ height: '100%', position: 'relative' }}>
        <h3 className={'card_title'}> Реквизиты</h3>
        <AddCard />
        <h5 className={'card_active'}>Активность</h5>
        <CardsSwitcher />
        <CardsContent />
      </div>
    </RequisiteContainer>
  );
};
