import { useState } from 'react';
import styles from './Warning.module.css';
import { useGetSelfQuery } from '../../../../../../redux/services/TraderApi';
import { Loading } from '../../../../../../components/Loading/Loading';
import { ModalDefault } from '../../../../../../components/ModalDefault/ModalDefault';

export const Warning = () => {
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [blockedModal, setBlockedModal] = useState<boolean>(false);
  const { data: trader, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 });
  const warning = trader?.activity_warning === 'WARNING';
  const blocked = trader?.activity_warning === 'BANNED';
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      {warning ? (
        <div
          className={styles.warning}
          onClick={() => {
            setWarningModal(true);
          }}
        >
          <img src={'/warning-icon.png'} alt={'warning icon'} />
          <div>Замороженная сумма приближается к сумме баланса</div>
        </div>
      ) : null}
      {blocked ? (
        <div
          className={styles.error}
          onClick={() => {
            setBlockedModal(true);
          }}
        >
          <img src={'/error_icon.png'} alt={'error icon'} />
          <div>Ваша деятельность приостановлена</div>
        </div>
      ) : null}
      <ModalDefault
        imgTitle={'/warning-icon.png'}
        imgStyles={{ width: '26px', height: '26px', marginBottom: '4px' }}
        title={'Ваша деятельность может быть приостановлена.'}
        titleMargin={'4px'}
        active={warningModal}
        setActive={setWarningModal}
        text={
          'Сумма ваших замороженных средств приближается к сумме баланса. Пополните баланс своего кошелька.'
        }
      >
        {' '}
      </ModalDefault>
      <ModalDefault
        imgTitle={'/error_icon.png'}
        imgStyles={{ width: '26px', height: '26px', marginBottom: '4px' }}
        title={'Ваша деятельность приостановлена и новые заявки не поступают.'}
        titleMargin={'4px'}
        active={blockedModal}
        setActive={setBlockedModal}
        text={
          'Сумма ваших замороженных средств равна или превышает сумму баланса. Пополните баланс своего кошелька для активации карт и работы с новыми заявками.'
        }
      >
        {' '}
      </ModalDefault>
    </div>
  );
};
