import styles from './Button.module.css';
import React from 'react';

interface TButtonProps {
  text?: string;
  action?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  type?: 'button' | 'submit';
  filled?: boolean;
  id?: string;
  children?: React.ReactNode;
}

export const Button = (props: TButtonProps) => {
  const { text, action, style, disabled, type, filled = true, id = '', children } = props;
  const getClass = () => {
    if (disabled) {
      return `${styles.button} ${styles.disabled}`;
    } else if (filled) {
      return styles.button;
    } else {
      return `${styles.button} ${styles.notFilled}`;
    }
  };
  return (
    <button
      id={id}
      className={getClass()}
      style={style}
      type={type}
      onClick={action}
      disabled={disabled}
    >
      {text ? text : children}
    </button>
  );
};
