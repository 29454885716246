import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { backendUrl } from '../../utils/constants/url';
import { getTokenFromLocalStorage } from '../../utils/functions/getToken';
import { IGetWithdrawalRequest, IWithdrawalsResponse, TFile } from '../types/WithdrawalsTypes';

export const WithdrawalsApi = createApi({
  reducerPath: 'WithdrawalsApi',
  tagTypes: ['Withdrawals'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getWithdrawals: build.query<IWithdrawalsResponse, IGetWithdrawalRequest>({
      query: (params) => ({
        params: {
          status: params?.status || undefined,
          page_size: params?.page_size || 0,
          page: params?.page || 1,
          id: params?.id || undefined,
        },
        url: '/withdrawals/',
        method: 'GET',
      }),
      providesTags: ['Withdrawals'],
    }),
    updateWithdrawalStatus: build.mutation({
      query: (options: { body: FormData; id: string }) => {
        const { body, id } = options;
        return {
          url: `/withdrawals/${id}/change_withdrawal/`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Withdrawals'],
    }),
    getFiles: build.query<{ files: TFile[] }, { id: string }>({
      query: (params) => ({
        url: `/withdrawals/${params.id}/file/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetWithdrawalsQuery, useUpdateWithdrawalStatusMutation, useGetFilesQuery } =
  WithdrawalsApi;
