import styles from './Filter.module.css';
import { useRef, useState } from 'react';
import { TFilterProps } from './types';
import { useOnClickOutside } from '../../../../../utils/hooks/useOnClickOutside';
import { getStatusById } from '../../../../../utils/functions/getStatusById';

export const Filter = (props: TFilterProps) => {
  const { changeFilter, activeFilter, filters } = props;
  const [open, setOpen] = useState<boolean>(false);
  const filterMenuRef = useRef(null);
  const closeMenu = () => setOpen(false);
  useOnClickOutside(filterMenuRef, closeMenu);
  const handleClick = (filter: string) => {
    changeFilter(filter);
    setOpen(false);
  };

  return (
    <div ref={filterMenuRef} className={styles.dropdown} style={{ marginBottom: '16px' }}>
      <div
        className={styles.dropdown_head}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span>
          {activeFilter === ''
            ? 'Все'
            : filters.find((filter) => filter.filterId === activeFilter)?.filterText}
        </span>
        <img src={open ? '/arrow_up_icon.png' : '/arrow_down_icon.png'} alt={'arrow down icon'} />
      </div>
      {open && (
        <ul>
          {filters.map(
            (filter) =>
              !(activeFilter === filter.filterText) && (
                <li onClick={() => handleClick(filter.filterId)}>
                  <span className={styles.app_option_text}>{filter.filterText}</span>
                </li>
              ),
          )}
        </ul>
      )}
    </div>
  );
};
