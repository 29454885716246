import { TFilterItem } from '../../pages/Trader/OrdersAndWithdrawals/components/Filter/types';

export const ordersFilters: TFilterItem[] = [
  {
    filterId: '',
    filterText: 'Все',
  },
  {
    filterId: 'BN',
    filterText: 'Новые заявки',
  },
  {
    filterId: 'SF,AA',
    filterText: 'Завершена успешно',
  },
  {
    filterId: 'ID',
    filterText: 'Спор',
  },
  {
    filterId: 'FR',
    filterText: 'Завершенные с перерасчетом',
  },
  {
    filterId: 'UR',
    filterText: 'Заявка с перерасчетом',
  },
  {
    filterId: 'TO',
    filterText: 'Просроченные',
  },
  {
    filterId: 'IP',
    filterText: 'В процессе',
  },
  {
    filterId: 'CR',
    filterText: 'Перерасчет отклонен',
  },
  {
    filterId: 'AAD',
    filterText: 'Автоматически одобрена из диспута',
  },
];
export const withdrawalsFilters: TFilterItem[] = [
  {
    filterId: '',
    filterText: 'Все',
  },
  {
    filterId: 'BN',
    filterText: 'Новая заявка',
  },
  {
    filterId: 'IP',
    filterText: 'В процессе',
  },
  {
    filterId: 'SF',
    filterText: 'Завершена успешно',
  },
  {
    filterId: 'PC',
    filterText: 'Проблема подтверждена',
  },
  {
    filterId: 'STS',
    filterText: 'Отправлена в поддержку',
  },
  {
    filterId: 'ID',
    filterText: 'Спор',
  },
  {
    filterId: 'STSD',
    filterText: 'Спор отправлен в поддержку',
  },
  {
    filterId: 'UR',
    filterText: 'Перерасчет отклонен',
  },
  {
    filterId: 'UR',
    filterText: 'Заявка с перерасчетом',
  },
  {
    filterId: 'FR',
    filterText: 'Завершена с перерасчетом',
  },
];
