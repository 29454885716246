import styles from './ControlledInput.module.css';
import { useFormContext } from 'react-hook-form';
import { type IInputProps } from '../BaseInput/BaseInput';
import React from 'react';

interface IControlledInputProps extends IInputProps {
  transform?: (text: string | undefined) => string | undefined | number;
  calculateNewPosition?: (
    currentPosition: number,
    newPosition: number,
    valueLen: number,
    value: string,
  ) => number;
  isOnlyletters?: boolean;
}

export const ControlledInput = (props: IControlledInputProps) => {
  const {
    title,
    placeholder,
    name,
    rules = {},
    type = 'text',
    imgRight,
    imgRightWidth = 0,
    imgRightAction,
    imgLeft,
    imgLeftWidth = 0,
    required = false,
    blockStyle,
    transform,
    defaultValue = '',
    inputStyle,
    errorText = '',
    calculateNewPosition,
    disabled,
    isOnlyletters = false,
  } = props;
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentPosition = e.target.selectionStart || 0;
    if (transform && e.target.value) {
      const newValue = transform(e.target.value);
      setValue(name, newValue);
      let newPosition = Math.min(currentPosition, String(newValue).length);
      const pos =
        calculateNewPosition &&
        calculateNewPosition(
          currentPosition,
          newPosition,
          String(newValue).length,
          String(newValue),
        );
      const inputElement = document.querySelector(`input[name="${name}"]`) as HTMLInputElement;
      inputElement.setSelectionRange(pos || newPosition, pos || newPosition);
    }
  };

  return (
    <div className={styles.inputBlock} style={blockStyle}>
      <div className={styles.title}>
        {required && <span style={{ color: '#FE5B37' }}>*</span>}
        {title}
      </div>
      <div className={styles.input}>
        {imgLeft && (
          <img
            src={imgLeft}
            className={styles.imgLeft}
            style={{ width: imgLeftWidth }}
            alt={imgLeft}
          />
        )}
        <input
          style={imgLeft ? { paddingLeft: `${imgLeftWidth + 22}px` } : inputStyle}
          className={errors?.[name] ? `${styles.field} ${styles.error}` : styles.field}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          {...register(name, rules)}
          defaultValue={defaultValue}
          onChange={(e) => {
            if (transform && (!isOnlyletters || e.target.value.match(/^[a-zA-Z]+$/))) {
              handleInputChange(e);
            }
          }}
        />
        {imgRight && (
          <img
            src={imgRight}
            className={styles.imgRight}
            style={{ width: imgRightWidth }}
            onClick={imgRightAction}
            alt={imgRight}
          />
        )}
      </div>
      <div className={styles.errorSpan}>{errors?.[name] && errorText}</div>
    </div>
  );
};
